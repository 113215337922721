document.addEventListener("DOMContentLoaded", () => {
    const body = document.querySelector('body');
    const burger = document.querySelector('.navbar__burger');
    const burgerMenu = document.querySelector('.navbar__list');
    const navbar = document.querySelector('.navbar');

    const hideBurgerMenu = () => {
        burgerMenu.className = 'navbar__list';
        burger.className = 'navbar__burger';
        body.classList.remove('overflow');
        navbar.classList.remove('active')
    };

    body.addEventListener('click', (e) => {
        const targetClasses = e.target.classList;
        if (!targetClasses.contains('navbar__burger') && !targetClasses.contains('navbar__link')) {
            hideBurgerMenu();
        }
    });

    const initBurgerMenu = () => {
        burger.addEventListener('click', (e)=>{
            if(e.target.classList.contains('navbar__burger')) {
                burgerMenu.classList.toggle('active');
                burger.classList.toggle('active');
                body.classList.toggle('overflow')
                navbar.classList.toggle('active')
            }
        });

        burgerMenu.addEventListener('click', (e) => {
            const burgerItem = e.target;
            if(burgerItem.classList.contains('navbar__link')) {
                hideBurgerMenu();
            }
        });
    }

    const initPopup = () => {
        const popupWrap = document.querySelector('.services__list');
        const popup = document.querySelector('.services-popup')
        const popupTitle = document.querySelector('.services-popup__title')
        const popupDesc = document.querySelector('.services-popup__desc')
        let classPopup
            popupWrap.addEventListener('click', (e) => {
                const target = e.target;

                if(target.classList.contains('services-item') || target.parentElement.classList.contains('services-item')) {
                    let serviceItem = target.classList.contains('services-item') ? target : target.parentElement;
                    let popupText = serviceItem.dataset.popupText;
                    let popupNum = serviceItem.dataset.popup;
                    let popupTitleText = document.querySelector(`.services-item__title[data-popup='${popupNum}']`).innerHTML;
                    let popupShow = popup.classList.contains('active');

                    if(popupShow) {
                        let delClass = classPopup;
                        popup.classList.remove('active');
                        setTimeout(() => popup.classList.remove(delClass), 300)
                    }
                    popupDesc.innerText = popupText;
                    classPopup = 'services-popup--pos' + '-' + `${popupNum}`;
                    popupTitle.innerHTML = popupTitleText;
                    if(!popupShow) {
                        popup.classList.add('active', classPopup);
                    } else {
                        setTimeout(() => popup.classList.add('active', classPopup), 300)
                    }
                }

                if(target.classList.contains('services-popup__close')) {
                    let delClass = classPopup;
                    popup.classList.remove('active');
                    setTimeout(() => popup.classList.remove(delClass), 500)
                }
            })
    }

    const initDropdown = () => {
        const dropdownBtnPlus = document.querySelectorAll('.vacancies-item__btn[data-attr]');
        const dropdownBtnText = document.querySelectorAll('.vacancies-item__name[data-attr]');

        dropdownBtnPlus.forEach(elem => {
            elem.addEventListener('click', () => {
                let type = elem.dataset.attr;
                let dropdown = document.querySelector(`.dropdown[data-attr='${type}']`);

                elem.classList.toggle('active');
                dropdown.classList.toggle('active');
                dropdown.classList.toggle('close');
            })
        })

        dropdownBtnText.forEach((elem,index)=> {
            elem.addEventListener('click', () => {
                let type = elem.dataset.attr;
                let dropdown = document.querySelector(`.dropdown[data-attr='${type}']`);

                dropdownBtnPlus[index].classList.toggle('active');
                dropdown.classList.toggle('active');
                dropdown.classList.toggle('close');
            })
        })
    }

    let animateCounter = false;

    const counter = (obj, start, duration) => {
        let startTimestamp = null;
        let end = obj.dataset.counter;
        const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / duration, 1);
            obj.innerHTML = Math.floor(progress * (end - start) + start);
            if (progress < 1) {
                window.requestAnimationFrame(step);
            }
        };
        window.requestAnimationFrame(step);
    }

    const initCounter = () => {
        const numbers = document.querySelectorAll('.about__number');
        numbers.forEach(el => counter(el, 0, 2000))
    }

    const priceNumbers = document.querySelectorAll('.price-component__desc');
    const priceLines = document.querySelectorAll('.price-component__path');
    const vacanciesLines = document.querySelectorAll('.vacancies-item__line');

    window.addEventListener('scroll', () => {
        let windowHeight = window.innerHeight;
        let achievesObj = document.querySelector('.about__achievements').getBoundingClientRect();

        vacanciesLines.forEach(line => {
            let lineObj = line.getBoundingClientRect();
            if(lineObj.top - windowHeight + lineObj.height < 0) {
                line.style.width = '100%';
            }
        })

        if(achievesObj.top - windowHeight + achievesObj.height/2 < 0 && !animateCounter) {
                animateCounter = true;
                initCounter();
        }

        priceNumbers.forEach((el, index) => {
            let startAnimationObj = el.getBoundingClientRect();
            if(startAnimationObj.top - windowHeight + startAnimationObj.height*2 < 0) {
                priceLines[index].classList.add('active');
                priceNumbers[index].classList.add('active');
            }
        })
    })

    const mediaQuery = window.matchMedia('(max-width: 768px)')
    const serviceItems = document.querySelectorAll('.services-item');

    const changeAnimationDelay = (e) => {
        if(e.matches) {
            serviceItems.forEach((item) => item.dataset.aosDelay = '200')
        }
    }
    mediaQuery.addListener(changeAnimationDelay);
    changeAnimationDelay(mediaQuery)

    AOS.init({
        duration: 1000,
        easing: 'ease',
    });

    initPopup();
    initBurgerMenu();
    initDropdown();
});
